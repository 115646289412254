import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/Alternative_IP_Scanner/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Fing CLI",
  "path": "/Quick_Installation/Alternative_IP_Scanner/Fing_CLI/",
  "dateChanged": "2017-09-26",
  "author": "Mike Polinowski",
  "excerpt": "Use the Fing Command Line Tool to discover your Camera on your local Network",
  "image": "./QI_SearchThumb_AlternativeIPScanner_FingCLI_eng.png",
  "social": "/images/Search/QI_SearchThumb_AlternativeIPScanner_FingCLI_eng.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-Alternative_IP_Scanner_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Fing CLI' dateChanged='2017-09-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use the Fing Command Line Tool to discover your Camera on your local Network' image='/images/Search/QI_SearchThumb_AlternativeIPScanner_FingCLI_eng.png' twitter='/images/Search/QI_SearchThumb_AlternativeIPScanner_FingCLI_eng.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Alternative_IP_Scanner/Fing_CLI/' locationFR='/fr/Quick_Installation/Alternative_IP_Scanner/Fing_CLI/' crumbLabel="Fing CLI" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "alternative-ip-scanner",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#alternative-ip-scanner",
        "aria-label": "alternative ip scanner permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alternative IP Scanner`}</h1>
    <h2 {...{
      "id": "fing-cli",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#fing-cli",
        "aria-label": "fing cli permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Fing CLI`}</h2>
    <h4 {...{
      "id": "how-to-find-your-ip-camera-in-the-network-with-an-alternative-ip-scanner",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#how-to-find-your-ip-camera-in-the-network-with-an-alternative-ip-scanner",
        "aria-label": "how to find your ip camera in the network with an alternative ip scanner permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to find your IP Camera in the network with an alternative IP Scanner?`}</h4>
    <p>{`The `}<a parentName="p" {...{
        "href": "http://instar.de/media/wysiwyg/Products/Software/Camera_Tool_Win/INSTAR_Camera_Tool_2.0.5.msi.zip"
      }}>{`IP Camera Tool (Windows)`}</a>{`, `}<a href="https://itunes.apple.com/de/app/instar-camera-tool/id584435076?mt=12#" target="_blank" rel="noopener noreferrer">{`IP Camera Tool (macOS)`}</a>{` or `}<a parentName="p" {...{
        "href": "http://instar.de/media/wysiwyg/Products/Software/Camera_Tool_Linux/INSTAR_Camera_Tool_LINUX.zip"
      }}>{`IP Camera Tool (LINUX)`}</a>{` is for sure the most comfortable way to find your IP camera inside your network. Instructions on how to set up your INSTAR IP camera with the Camera Tool can be found here.`}</p>
    <p>{`If, for some reason, the IP Camera Tool does not work on your computer, here are a few other options to discover the IP address. The camera will try to receive an IP address automatically (DHCP) during the first installation. Most routers will give you a log file of fresh DHCP requests, in which you should be able to find the camera´s IP address. You should reach the cameras login portal by typing this address into the web browser´s address bar.`}</p>
    <p>{`If you do not have access to your router or if your router does not have any log files, please refer to alternative IP scanners - an examples is:`}</p>
    <h3 {...{
      "id": "fing-cli-windowsmacosxlinux",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#fing-cli-windowsmacosxlinux",
        "aria-label": "fing cli windowsmacosxlinux permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Fing CLI (Windows/MacOSX/Linux)`}</h3>
    <p>{`The newest version of the `}<em parentName="p">{`freeware`}</em>{` application `}<strong parentName="p">{`Fing`}</strong>{` can be found on `}<a href="https://www.fingbox.com/download" target="_blank" rel="noopener noreferrer">{`HERE for Windows/macOSX/LINUX`}</a>{`. After installation type fing into your command console to start the software:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2986e7c78334c25fe6a03db34ac7b541/fc2a6/IPScanner_Windows_Fing_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "15.652173913043477%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA6UlEQVQI1y2JzUvCcABABxWUXYvAQ0kXA6mbM4clHjoE6mR0qJFFX6twKydBoOShQ7iPasW236Bj16JOQUR/2wulw4PHe5L/8kooEpI4QoiYRAiegkdcZ4Dnudz7Hq7r4DgD/H8fviAIEHFEGIU8+x4/pwafto3U6j6gGbc0jvqoh3108476wQ1yzaJQv0CuWhTVS5TGkDb5qkmhZlHSbMpbVxTVNiWtw8b2NW/vH0ibzR5p5ZzFisVs/pj5tRaZssnCuklaOWNu1WB6ZZ/U8h5TuSYTSzpj2Z2RT+Z2Gc/qozYjn/D1/csfi8mCYQwA4J0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2986e7c78334c25fe6a03db34ac7b541/e4706/IPScanner_Windows_Fing_01.avif 230w", "/en/static/2986e7c78334c25fe6a03db34ac7b541/e46ac/IPScanner_Windows_Fing_01.avif 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2986e7c78334c25fe6a03db34ac7b541/a0b58/IPScanner_Windows_Fing_01.webp 230w", "/en/static/2986e7c78334c25fe6a03db34ac7b541/8626f/IPScanner_Windows_Fing_01.webp 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2986e7c78334c25fe6a03db34ac7b541/81c8e/IPScanner_Windows_Fing_01.png 230w", "/en/static/2986e7c78334c25fe6a03db34ac7b541/fc2a6/IPScanner_Windows_Fing_01.png 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2986e7c78334c25fe6a03db34ac7b541/fc2a6/IPScanner_Windows_Fing_01.png",
              "alt": "Fing Command Line Interface",
              "title": "Fing Command Line Interface",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Type fing into your command line tool to start the program.`}</p>
    <p>{`Make sure that your camera is unplugged - then scan your network. Now connect the camera to your router and re-run the scan. The camera should have received an IP address by DHCP and be listed as a network device:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0d8a948deca4b8720fda524435e74c1f/fc2a6/IPScanner_Windows_Fing_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "109.13043478260872%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAD8UlEQVQ4y4WU21NTVxTGj1pHrXWkIG2tVCFyS0ggFwiESwK5kISLpOZ+gpAwhlvuFy6BBHGczlSFJEIdsYyOPnXGB+tjpzN96EynQ0FC+w+dr7N3YoxS6MOanZOzz29/61trLybz8hW2X7zEs6ePkV77HpvZdWTWH2Aju45s+iEy6/fxKPMQG9k1ZNMPkF67j82NDPfi+Tae//iEe7K9xf303V289Yxxb2ZnwTh9d6GyRNHviEFjDUNtCdFVZ4/S0Noi0JEo/Nbm33EGdg4GZ5wzsnNcnyUM58wq9/rnN2DaBgO42OxGWYsb5wSjuCyfxOkGFsxVM5gaKxie7b+jxsqR9ydq7aiQTHBtpiX8/scOGJU5hgZ1ENXd06hsnYDYEEFNzzSqFF5USN24ILqF80IXPhOOlgZH1vNCF1fWMsY1aYPQsne4X379DUzroB+ywTmUS924JPOg15aEyroMvWsVTboQPYThWXCqzo6TdbaSsONErQ1n+Sx0rlW4o5v4a28/n3L7yCL9sELqgcaRQo85gT57EiJdCHxNEMy1m/Rj5rq1GPSZZ6XA9pF5TMR+wH7ubzCtA36IjTGq7nPxOJTmJciH56AwLUCgDaKu1w+m2kwBHwcBnxWwkBijYAOZAnAwgBZDDOUSNw2irtO0gO6bCbqRpE0UnjwKyGchG4rTlPdzB3mFkoE49bBc6oHSskyhvdZltN+Y/3+ggEWXOYHb84+RIwo/8FDmgdaxAqVliXop0ochG4wfnzKfxcD4PYRXnx32sEw8jp6Ch52mRdT3+iEfnn8PLBSjFHim0QmldRkzS0+ROygo/NDDJVoQkjYpSsfIApgay5EKz/BZCpxObOWBRQ8JUOqm6ZKC9NmSEOpC9ADSh8cBVbYkphJb+ZQPeehcoYUhXhKgjr1ztEKeBZ8KR9E2PIex8CPkDv5552H8UB8SDxv6/Oj6NnFsyqQoYmMUDn8au29zx3tI7iix4GiFVpxrclEgaeyih0RhKZCoIn1Y9LBQZebjKhOg4D3wkIeXCh6qLMvQOFbQrI9AbU9R4FGNTRT2OVLwxDaxu5fLKyTTprLgIZk0ipEFqoyvDhSB7/qwdECQopAqK0yLGI9s5BVKjD5cpMOVxYXmW7jS4cXXHV5cUUzii9YJfCW/TWGf1DvoSkbXqXo7Tjc46H8ESPZ121LY2d0Ho2cX8aXcC742SMfX1a4p8JSzqO6ZQVXnFKo6J3Fd5cM3XVP0XjdqgrjWPY3L7V66V6ANURGdlmQeeGMsCZ7Sh7ahOCplExDpI/T+CvvDaFQHIOoP06lTq/KhdSiOFkOUthO5ls36MD1EoAlAaUvhz509/AviNzsOCdqOBAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0d8a948deca4b8720fda524435e74c1f/e4706/IPScanner_Windows_Fing_02.avif 230w", "/en/static/0d8a948deca4b8720fda524435e74c1f/e46ac/IPScanner_Windows_Fing_02.avif 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0d8a948deca4b8720fda524435e74c1f/a0b58/IPScanner_Windows_Fing_02.webp 230w", "/en/static/0d8a948deca4b8720fda524435e74c1f/8626f/IPScanner_Windows_Fing_02.webp 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0d8a948deca4b8720fda524435e74c1f/81c8e/IPScanner_Windows_Fing_02.png 230w", "/en/static/0d8a948deca4b8720fda524435e74c1f/fc2a6/IPScanner_Windows_Fing_02.png 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0d8a948deca4b8720fda524435e74c1f/fc2a6/IPScanner_Windows_Fing_02.png",
              "alt": "Fing Command Line Interface",
              "title": "Fing Command Line Interface",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Let the Fing scan your network for a new active host.`}</p>
    <p>{`You can now use the camera´s IP address to access the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Overview/"
      }}>{`Web User Interface`}</a>{` with your default web browser.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      